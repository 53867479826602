<template>
  <!--
  *@des:高层次人才经济贡献奖
  -->
  <section class="form-wrapper">
    <van-form ref="form">
      <van-field
        v-model="form.applyName"
        name="applyName"
        label="姓名"
        placeholder="请输入姓名"
        required
      />
      <van-field-select
        v-model="form.idCardType"
        :options="idCardTypes"
        :fieldProps="{
          label: '证件类型',
          required: true,
          placeholder: '请选择证件类型',
        }"
      ></van-field-select>
      <div style="display: flex">
        <van-field
          v-model="form.idCardNum"
          name="idCardNum"
          label="证件号码"
          placeholder="请输入证件号码"
          required
        />
        <van-button class="idCardNum-button" @click="gccrcsbhy"
          >查询证书</van-button
        >
      </div>

      <van-field-area
        v-model="form.domicile"
        name="domicile"
        :fieldProps="{
          required: true,
          label: '户籍地址',
          placeholder: '请选择户籍地址',
        }"
        defaultField=""
      />
      <van-field-select
        v-model="form.nation"
        :options="nations"
        :fieldProps="{
          label: '民族',
          required: true,
          placeholder: '请选择民族',
        }"
      ></van-field-select>
      <van-field
        v-model="form.phone"
        name="phone"
        label="手机号"
        placeholder="请输入手机号"
        required
        maxlength="11"
      />
      <van-field-select
        v-model="form.politicCountenance"
        :options="politics"
        :fieldProps="{
          label: '政治面貌',
          required: true,
          placeholder: '请选择政治面貌',
        }"
      ></van-field-select>
      <van-field
        v-model="form.highTalentType"
        name="phone"
        label="高层次人才类别"
        placeholder="请输入高层次人才类别"
        required
        :disabled="hasHighTalent"
      />
      <van-field
        v-model="form.bankId"
        name="bankId"
        label="社会保障卡开户银行"
        placeholder="请输入社会保障卡开户银行"
        required
        maxlength="100"
      />
      <van-field
        v-model="form.bankAccount"
        name="bankAccount"
        label="社会保障卡金融账号"
        placeholder="请输入社会保障卡金融账号"
        required
        maxlength="20"
      />
      <van-field
        v-model="form.preYearSalary"
        name="preYearSalary"
        label="上年度年度薪资(元)"
        placeholder="请输入上年度年度薪资(元)"
        required
        maxlength="20"
      />
      <van-field
        v-model="form.preYearRatal"
        name="preYearRatal"
        label="上年度工资薪金纳税额(元)"
        placeholder="请输入上年度工资薪金纳税额(元)"
        required
        maxlength="20"
      />
      <van-field
        v-model="form.workCmpy"
        name="workCmpy"
        label="工作单位"
        placeholder="请输入工作单位"
        required
        maxlength="100"
      />
    </van-form>
  </section>
</template>

<script>
import formMixin from "../mixin";
import Schema from "async-validator";
import { gccrcsbhy } from "@/api/policy/apply";
import { nations } from "../config";
export default {
  name: "high-talent-devote",
  mixins: [formMixin],
  data() {
    return {
      form: {
        applyName: "", //姓名
        idCardType: "居民身份证", //证件类型
        idCardNum: "", //证件号码
        domicile: [], //户籍地址
        nation: "", //民族
        politicCountenance: "", //政治面貌
        phone: "",
        highTalentType: "", //高层次人才类别
        bankId: "", //社会保障卡开户银行
        bankAccount: "", //社会保障卡金融账号
        workCmpy: "", //工作单位
        preYearSalary: "", //薪资
        preYearRatal: "", //纳税额
      },
      formRules: {
        domicile: {
          type: "array",
          required: true,
          message: "请选择户籍地址",
        },
        nation: {
          required: true,
          message: "请选择民族",
        },
        politicCountenance: {
          required: true,
          message: "请选择政治面貌",
        },
        bankId: {
          required: true,
          message: "请输入开户银行",
        },
        bankAccount: {
          required: true,
          message: "请输入金融账号",
        },
        preYearSalary: [
          {
            required: true,
            message: "请输入上年度薪资",
          },
          {
            message: "年度薪资请输入最多两位小数的数字",
            pattern: /^100000000$|^([0-9]{0,8})(\.(\d){0,2})?$/,
            type: "string",
          },
        ],
        preYearRatal: [
          {
            required: true,
            message: "请输入上年度薪资纳税额",
          },
          {
            message: "纳税额请输入最多两位小数的数字",
            pattern: /^100000000$|^([0-9]{0,8})(\.(\d){0,2})?$/,
            type: "string",
          },
        ],
        workCmpy: {
          required: true,
          message: "请输入工作单位",
        },
      },
      idCardTypes: [
        {
          value: "居民身份证",
          label: "居民身份证",
        },
        {
          value: "护照",
          label: "护照",
        },
      ],
      nations: nations,
      politics: [
        { value: "中共党员(含预备党员)", label: "中共党员(含预备党员)" },
        { value: "民主党派", label: "民主党派" },
        { value: "无党派人士", label: "无党派人士" },
        { value: "团员", label: "团员" },
        { value: "群众", label: "群众" },
      ],
      hasHighTalent: true,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.global.userInfo;
    },
  },
  created() {},
  methods: {
    /*
     *@des: 获取高层次人才信息
     */
    gccrcsbhy() {
      if (
        !this.form.applyName ||
        !this.form.idCardNum ||
        !this.form.idCardType
      ) {
        this.$toast("用户名与证件号码不能为空");
        return;
      }
      const idCardRule =
        /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/;
      if (
        "居民身份证" == this.form.idCardType &&
        !idCardRule.test(this.form.idCardNum)
      ) {
        this.$toast("证件号格式不正确");
        return;
      }
      gccrcsbhy({
        perCard: this.form.idCardNum,
        perName: this.form.applyName,
        type: "居民身份证" == this.form.idCardType ? "1" : "0",
      })
        .then((res) => {
          let { success, data } = res;
          if (success) {
            this.form.highTalentType = data[0].endCertificateName;
          } else {
            this.$toast("暂无高层次人才认证,请手动输入高层次人才类别");
            this.hasHighTalent = false;
          }
        })
        .catch((e) => {
          this.$toast("暂无高层次人才认证,请手动输入高层次人才类别");
          this.hasHighTalent = false;
        });
    },
    /*
     *@des: 初始化校验
     */
    initFormValidator() {
      const idCardRule = [
        {
          required: true,
          message: "请输入证件号",
          trigger: "blur",
        },
        {
          pattern:
            /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,
          message: "证件号格式不正确",
          trigger: "blur",
        },
      ];
      const passPortRule = {
        required: true,
        message: "请输入证件号",
        trigger: "blur",
      };
      if ("居民身份证" == this.form.idCardType) {
        this.formRules.idCardNum = idCardRule;
      } else {
        this.formRules.idCardNum = passPortRule;
      }
      this.formValidator = new Schema(this.formRules);
    },
    /*
     *@des: 校验表单
     */
    validate() {
      this.initFormValidator();
      return new Promise((resolve, reject) => {
        this.formValidator
          .validate(this.form, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(async () => {
            let [proCode, cityCode, countyCode] = this.form.domicile;
            resolve({
              ...this.form,
              proCode,
              cityCode,
              countyCode,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style  lang="scss" scoped>
.idCardNum-button {
  width: 2rem;
  padding: 0 0.15rem;
}
</style>