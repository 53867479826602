var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"form-wrapper"},[_c('van-form',{ref:"form"},[_c('van-field',{attrs:{"name":"applyName","label":"姓名","placeholder":"请输入姓名","required":""},model:{value:(_vm.form.applyName),callback:function ($$v) {_vm.$set(_vm.form, "applyName", $$v)},expression:"form.applyName"}}),_c('van-field-select',{attrs:{"options":_vm.idCardTypes,"fieldProps":{
        label: '证件类型',
        required: true,
        placeholder: '请选择证件类型',
      }},model:{value:(_vm.form.idCardType),callback:function ($$v) {_vm.$set(_vm.form, "idCardType", $$v)},expression:"form.idCardType"}}),_c('div',{staticStyle:{"display":"flex"}},[_c('van-field',{attrs:{"name":"idCardNum","label":"证件号码","placeholder":"请输入证件号码","required":""},model:{value:(_vm.form.idCardNum),callback:function ($$v) {_vm.$set(_vm.form, "idCardNum", $$v)},expression:"form.idCardNum"}}),_c('van-button',{staticClass:"idCardNum-button",on:{"click":_vm.gccrcsbhy}},[_vm._v("查询证书")])],1),_c('van-field-area',{attrs:{"name":"domicile","fieldProps":{
        required: true,
        label: '户籍地址',
        placeholder: '请选择户籍地址',
      },"defaultField":""},model:{value:(_vm.form.domicile),callback:function ($$v) {_vm.$set(_vm.form, "domicile", $$v)},expression:"form.domicile"}}),_c('van-field-select',{attrs:{"options":_vm.nations,"fieldProps":{
        label: '民族',
        required: true,
        placeholder: '请选择民族',
      }},model:{value:(_vm.form.nation),callback:function ($$v) {_vm.$set(_vm.form, "nation", $$v)},expression:"form.nation"}}),_c('van-field',{attrs:{"name":"phone","label":"手机号","placeholder":"请输入手机号","required":"","maxlength":"11"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('van-field-select',{attrs:{"options":_vm.politics,"fieldProps":{
        label: '政治面貌',
        required: true,
        placeholder: '请选择政治面貌',
      }},model:{value:(_vm.form.politicCountenance),callback:function ($$v) {_vm.$set(_vm.form, "politicCountenance", $$v)},expression:"form.politicCountenance"}}),_c('van-field',{attrs:{"name":"phone","label":"高层次人才类别","placeholder":"请输入高层次人才类别","required":"","disabled":_vm.hasHighTalent},model:{value:(_vm.form.highTalentType),callback:function ($$v) {_vm.$set(_vm.form, "highTalentType", $$v)},expression:"form.highTalentType"}}),_c('van-field',{attrs:{"name":"bankId","label":"社会保障卡开户银行","placeholder":"请输入社会保障卡开户银行","required":"","maxlength":"100"},model:{value:(_vm.form.bankId),callback:function ($$v) {_vm.$set(_vm.form, "bankId", $$v)},expression:"form.bankId"}}),_c('van-field',{attrs:{"name":"bankAccount","label":"社会保障卡金融账号","placeholder":"请输入社会保障卡金融账号","required":"","maxlength":"20"},model:{value:(_vm.form.bankAccount),callback:function ($$v) {_vm.$set(_vm.form, "bankAccount", $$v)},expression:"form.bankAccount"}}),_c('van-field',{attrs:{"name":"preYearSalary","label":"上年度年度薪资(元)","placeholder":"请输入上年度年度薪资(元)","required":"","maxlength":"20"},model:{value:(_vm.form.preYearSalary),callback:function ($$v) {_vm.$set(_vm.form, "preYearSalary", $$v)},expression:"form.preYearSalary"}}),_c('van-field',{attrs:{"name":"preYearRatal","label":"上年度工资薪金纳税额(元)","placeholder":"请输入上年度工资薪金纳税额(元)","required":"","maxlength":"20"},model:{value:(_vm.form.preYearRatal),callback:function ($$v) {_vm.$set(_vm.form, "preYearRatal", $$v)},expression:"form.preYearRatal"}}),_c('van-field',{attrs:{"name":"workCmpy","label":"工作单位","placeholder":"请输入工作单位","required":"","maxlength":"100"},model:{value:(_vm.form.workCmpy),callback:function ($$v) {_vm.$set(_vm.form, "workCmpy", $$v)},expression:"form.workCmpy"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }